<template>
<div class="tripaddctxmenu-container" >
    <vs-button size="large" block transparent @click="callback('place')">
        <img src="../../assets/icons/place.png" style="width: 17px; height: 17px;" />{{ $t('common.place') }}
    </vs-button>
    <vs-button size="large" block transparent @click="callback('structure')">
        <img src="../../assets/icons/structure.png" style="width: 17px; height: 17px;" />{{ $t('common.stay') }}
    </vs-button>
</div>
</template>

<script>
export default {
    name: 'tripAddCtxMenu',
    props: {
        callback: Function
    }
}
</script>

<style scoped>
.tripaddctxmenu-container {
    display: flex;
    flex-direction: column;
    background: #ECEBED;
    border-radius: 11px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}
</style><style>
.tripaddctxmenu-container .vs-button__content {
    justify-content: flex-start;
    align-items: center;
    font-weight: 600!important;
    gap: 5px;

}


    
</style>
<style>
.tripaddctxmenu-container .vs-button {
    margin: 0;
    border-radius: 0!important;
    box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
}

</style>