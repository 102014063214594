<template>
<div>
    <div class="cancelsubscription-container">
        <h2>{{ $t('common.cancellation') }}</h2>

        <p style="max-width: 400px;">
            {{ $t('profile.subscription.cancellation.thanks') }}<br/>
            {{ $t('profile.subscription.cancellation.disclamer') }}<br/>
            {{ $t('profile.subscription.cancellation.confirmation') }}
        </p>
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-button size="large" border block @click="callback(false, true)">
                {{ $t('common.cancel') }}
            </vs-button>
            <vs-button size="large" block @click="callback(true, true)">
                {{ $t('common.continue') }}
            </vs-button>
        </div>
    </div>

</div>
</template>

    
<script>
export default {
    name: "cancelSubscriptionDialog",
    props: {
        callback: Function
    },
    data() {
        return {
            
        }
    },
    components: {}
}
</script>

    
<style scoped>
p,
h3,h2 {
    margin: 0;
}

.cancelsubscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
    width: 100%;
}
</style>
