<template>
<div class="invoicing-container" ref="invoicing_container">
    <div class="top">
        <div class="header">
            <h2 style="margin: 0;">{{ $t('profile.invoicing.title') }}</h2>
            <p style="margin: 0;">{{ $t('profile.invoicing.caption') }}</p>
        </div>
        <SubscriptionPending v-if="paymentPending" />

        <div v-else-if="stripeFormVisible == false" class="fields">
            <div class="field">
                <p>{{ $t('profile.invoicing.subject') }}</p>
                <vs-select v-model="user.billing_details.type" block>
                    <vs-option :label="$t('profile.invoicing.private')" value="PRIVATE">
                        {{ $t('profile.invoicing.private') }}
                    </vs-option>
                    <vs-option :label="$t('profile.invoicing.company')" value="BUSINESS">
                        {{ $t('profile.invoicing.company') }}
                    </vs-option>
                </vs-select>
            </div>
            <div class="field">
                <p>{{ $t('common.nameAndSurname') }}</p>
                <vs-input block v-model="user.billing_details.fullname" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.country') }}</p>
                <vs-input block v-model="user.billing_details.country" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.state') }}</p>
                <vs-input block v-model="user.billing_details.state" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.city') }}</p>
                <vs-input block v-model="user.billing_details.city" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.postalCode') }}</p>
                <vs-input block v-model="user.billing_details.postal_code" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p>{{ $t('common.street') }}</p>
                <vs-input block v-model="user.billing_details.line1" :placeholder="$t('profile.clickAndType')"/>
            </div>
            <div class="field">
                <p v-if="user.billing_details.type == 'PRIVATE'">{{ $t('profile.invoicing.fiscalCode') }}</p>
                <p v-else>{{ $t('profile.invoicing.vatNo') }}</p>
                <vs-input block v-model="user.billing_details.tax.value" :placeholder="$t('profile.clickAndType')"/>
            </div>
        </div>
        
        <div id="payment-element"></div>

    </div>
    <vs-button v-if="stripeFormVisible == false && !paymentPending" size="large" @click="submit">{{ $t('common.continue') }}</vs-button>
    <vs-button v-else-if="!paymentPending" size="large" @click="confirmPayment">{{ $t('common.continue') }}</vs-button>

</div>
</template>

<script>
import {
    apiCall
} from '../../utils/ApiMiddleware';
import {
    loadStripe
} from '@stripe/stripe-js';
import SubscriptionPending from './SubscriptionPending.vue';
export default {
    name: "invoicing",
    props: {

    },
    components: {
        SubscriptionPending
    },
    data() {
        return {
            user: {
                billing_details: {
                    fullname: '',
                    country: '',
                    state: '',
                    city: '',
                    postal_code: '',
                    line1: '',
                    tax: {
                        value: ''
                    },
                    type: 'PRIVATE'
                }
            },
            stripe: null,
            stripeElements: null,
            stripeFormVisible: true, // was false initially to show the form only after the user filled in the fields

            paymentPending: false

        }
    },

    async mounted() {
        this.stripe = await loadStripe(this.$STRIPE_PK);
        

        const loading = this.$vs.loading({
            target: this.$refs.invoicing_container
        });

        await this.getUser();

        this.lastTransaction = await this.getLastTransaction();
        if (this.lastTransaction != null) {
            // if coupon is passed, cancel the last transaction, in this way the new coupon will be applied
            if (this.$route.params.coupon != '') {
                if(await this.deleteSubscription(this.lastTransaction.id)){
                    this.lastTransaction = null;
                } else {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.subscription.promo.error'),
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            }

            // if new plan is not the same as the last transaction, cancel the last transaction
            if (this.lastTransaction != null && this.lastTransaction.plan.id != this.$route.params.plan_id) {
                if(await this.deleteSubscription(this.lastTransaction.id)){
                    this.lastTransaction = null;
                } else {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.subscription.promo.error'),
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            }


            
        }

        if(this.lastTransaction != null){
            var pi = await this.stripe.retrievePaymentIntent(this.lastTransaction.client_secret);
            if (['processing', 'requires_capture'].includes(pi.paymentIntent.status)) {
                this.paymentPending = true;
                loading.close();
                return;
            } else if (pi.paymentIntent.status == 'succeeded') {
                this.$router.push({
                    name: 'Attivata'
                })
            }
        }

        await this.submit(false);

        loading.close();
    },
    methods: {

        async deleteSubscription(id) {
            const response = await apiCall('DELETE', '/subscriptions/' + id);
            return response.status == 200;
        },

        async confirmPayment() {
            const loading = this.$vs.loading();
            const {
                error
            } = await this.stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements: this.stripeElements,
                confirmParams: {
                    return_url: window.location.origin + '/api/tourial/v1/subscriptions/check',
                }
            });
            loading.close();

            if (error) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.invoicing.messages.cannotPay'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async getUser() {
            let response = await apiCall('GET', '/users/info', {
                include_billing_details: true
            });
            await new Promise((resolve) => {
                if (response.status == 200) {
                    if (response.data.billing_details != null) {
                        for (var k in this.user.billing_details) {
                            if (response.data.billing_details[k] != null) {
                                this.user.billing_details[k] = response.data.billing_details[k];
                            }
                        }
                        if (this.user.billing_details.type == null || this.user.billing_details.type == '') {
                            this.user.billing_details.type = 'PRIVATE';
                        }
                    }
                    this.user.billing_details.fullname = '';
                    if(response.data.name != null && response.data.name != ''){
                        this.user.billing_details.fullname += response.data.name;
                    }

                    if(response.data.surname != null && response.data.surname != ''){
                        if(this.user.billing_details.fullname != null && this.user.billing_details.fullname != ''){
                            this.user.billing_details.fullname += ' ';
                        }
                        this.user.billing_details.fullname += response.data.surname;
                    }
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.messages.cannotLoad'),
                        color: 'danger',
                        position: 'top-right'
                    })
                }
                resolve();
            });
        },

        async getLastTransaction() {
            var response = await apiCall('GET', '/subscriptions', {
                limit: 1,
                order: 'desc(created_at)',
                only_active: false
            });
            if (response.status == 200) {
                if(response.data.data == null){
                    return null;
                }
                response.data.data = response.data.data.filter(e => e.status == 'PENDING'); // only pending transactions
                if (response.data.data.length > 0) {
                    return response.data.data[0];
                } else {
                    return null;
                }
            }
            return false;
        },

        async startTransaction() {
            const response = await apiCall('POST', '/subscriptions', {
                plan_id: this.$route.params.plan_id,
                coupon: this.$route.params.coupon != '' ? this.$route.params.coupon : undefined
            })
            if (response.status == 200) {
                // open url
                return response.data;
            }
            return false;
        },

        async saveUser() {
            let response = await apiCall('PATCH', '/users/info', {
                billing_details: this.user.billing_details
            });
            if (response.status == 200) {
                return true;
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.invoicing.messages.cannotSave'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            return false;
        },

        async submit(verifyMissingFields = true) {
            if(verifyMissingFields){
                // verify that all fields are filled
                for (var k in this.user.billing_details) {
                    if (this.user.billing_details[k] == null || this.user.billing_details[k] == '') {
                        this.$vs.notification({
                            title: this.$t('common.messages.somethingWentWrong'),
                            text: this.$t('profile.invoicing.messages.fillInAllFields'),
                            color: 'danger',
                            position: 'top-right'
                        })
                        return;
                    }
                }
                // verify tax code is filled in
                if (this.user.billing_details.tax.value == null || this.user.billing_details.tax.value == '') {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.invoicing.messages.fillInAllFields'),
                        color: 'danger',
                        position: 'top-right'
                    })
                    return;
                }
            }


            if (await this.saveUser()) { // Save user must be called to create stripe customer
                this.lastTransaction = await this.getLastTransaction(); // ask it again to be sure

                if(this.lastTransaction != null){
                    var pi = await this.stripe.retrievePaymentIntent(this.lastTransaction.client_secret);
                    if (['processing', 'requires_capture'].includes(pi.paymentIntent.status)) {
                        this.paymentPending = true;
                        return;
                    } else if(pi.paymentIntent.status == 'succeeded'){
                        this.$router.push({name: 'Attivata'})
                    } else if(pi.paymentIntent.status == 'canceled'){
                        this.lastTransaction = null; // force a new transaction
                    }
                }
                var r = null;

                if (this.lastTransaction === null) { // no transaction
                    r = await this.startTransaction();
                } else {
                    r = this.lastTransaction;
                }

                if (r === false) {
                    // show an error message
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.invoicing.messages.cannotPay'),
                        color: 'danger',
                        position: 'top-right'
                    })
                    return;
                }

                const options = {
                    clientSecret: r.client_secret,
                    // Fully customizable with appearance API.
                    appearance: {
                        /*...*/
                    },
                    
                };

                // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 5
                this.stripeElements = this.stripe.elements(options);

                // Create and mount the Payment Element
                const paymentElement = this.stripeElements.create('payment');
                paymentElement.mount('#payment-element');
                this.stripeFormVisible = true;

            }
        },
    },

    watch: {}
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 50%;
    max-width: 50%;
}

.invoicing-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

}

/* MOBILE */
@media (max-width: 600px) {
    .invoicing-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        height: unset !important;
        width: 100% !important;

    }

}
</style><style>
.invoicing-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.invoicing-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.invoicing-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}

.invoicing-container input.vs__search::placeholder, .invoicing-container .vs-input__label, .vs-select__label{
    color: #747474 !important;
    font-size: 15px !important;
    opacity: 1!important;
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 500!important;
}

.invoicing-container .vs-input:focus~.vs-input__label:not(.vs-input__label--placeholder):not(.vs-input__label--label){
    opacity: 0!important;
}

.invoicing-container .vs-select__label {
    margin-top: 10px!important;
}

.invoicing-container .StripeElement iframe {
    position: relative!important;
}
</style>
