<template>
  <div class="daydivider-container">
    <h4 style="color: var(--primary);">{{ $t('common.day') }} {{ dayNo }}</h4>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;" >
        <p class="bold"> {{ date.toLocaleString('default', { day: '2-digit' }) }}</p>
        <p class="light">{{ date.toLocaleString('default', { month: 'long' }).substr(0, 3).toUpperCase() }}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'dayDivider',
    props: {
        dayNo: Number,
        date: Date
    }
}
</script>

<style scoped>
.daydivider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 15px;
}

p,h4 {
    margin: 0;
}
</style>