<template>
<div class="subscriptionpending-container">
    <div class="top">
        <div style="display: flex; justify-content: center;">
            <img src="../../assets/images/trial.png" style="width: 60%;" />
        </div>
        <h2 style="text-align: center;">{{ $t('profile.subscription.pending.title') }}</h2>
        <p style="text-align: center;">{{ $t('profile.subscription.pending.caption') }}</p>
    </div>
    <div class="bottom">
        <vs-button size="large" @click="$router.push({name: 'Profilo'})">{{ $t('profile.subscription.cancelled.backToAccount').toUpperCase() }}</vs-button>
    </div>
</div>
</template>

    
<script>
export default {
    name: "subscriptionPending",
    props: {},
    components: {},
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

    
<style scoped>
.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.subscriptionpending-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

p,
h2 {
    margin: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .subscriptionpending-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        width: 100% !important;

    }

}
</style>
