<template>
<div>
    <div class="addtotripdialog-container">
        <h2>{{ $t('explore.addToTrip') }}</h2>
        <div class="actions">
            <vs-button transparent @click="callback(false, true)">{{ $t('common.cancel') }}</vs-button>
            <vs-button transparent @click="callback(null, true)" icon><p style="margin:0;">{{ $t('common.createNew') }}</p></vs-button>
        </div>
        <div class="trips">
            <vs-button transparent block :active="t == trip" v-for="t in trips" :key="'trip-'+t.id">
                <Trip @click.native="trip = t" :trip="t" :count-down="true" />
            </vs-button>
        </div>
        <vs-button size="large" block @click="callback(trip, true)" :disabled="trip == null">
            {{ $t('common.save') }}
        </vs-button>
    </div>

</div>
</template>

<script>
import {
    apiCall
} from '../../utils/ApiMiddleware';
import Trip from '../Trip.vue';

export default {
    name: "addtotripdialog",
    props: {
        callback: Function
    },
    components: {
        Trip
    },

    methods: {
        async getTrips() {

            // use apiCall to make a request to /trips
            const response = await apiCall('GET', '/journeys');
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (response.data.data != null) {
                    this.trips = response.data.data;
                } else {
                    this.trips = []
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.unableToLoadTrips'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
    },

    data() {
        return {
            trips: [],
            trip: null
        }
    },

    mounted() {
        this.getTrips();
    }
}
</script>

<style scoped>
.trips {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

p,
h3,
h2 {
    margin: 0;
}

img {
    margin-bottom: 20px;
}

.addtotripdialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
}
</style><style>
.addtotripdialog-container .trips .vs-button__content {
    padding: 0 !important;

}

.addtotripdialog-container .trips .vs-button {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

.addtotripdialog-container .vs-button__content .trip-container {
    width: 100%;
    font-size: 1rem !important;
}
</style>
