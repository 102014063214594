<template>
<div class="favourites-container">
    <div class="header">
        <h2 style="margin: 0;">{{ $t('favourites.title') }}</h2>
        <p style="margin: 0;">{{ $t('favourites.caption') }}</p>
    </div>
    <FavouritesSearch />
    <div class="favourites" v-if="pois.length > 0">
        <TripPoi v-for="poi in pois" :key="poi.id" :poi="poi" action="like" @change="getFavourites" />
    </div>
    <div v-else style="display: flex; flex-direction: column; gap: 20px; align-items: center;">
        <img src="../../assets/images/no_favourites.png" style="width: 50%;"/>
        <h3>{{ $t('favourites.noFavourites') }}</h3>
    </div>
</div>
</template>

<script>
import TripPoi from '../TripPoi.vue';
import NewTripDialog from '../dialogs/NewTripDialog.vue';
import FavouritesSearch from '../FavouritesSearch.vue';
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'
import {
    apiCall
} from '@/utils/ApiMiddleware.js';

export default {
    name: "favourites",
    props: {},
    components: {
        TripPoi,
        FavouritesSearch
    },

    data() {
        return {
            pois: [],
            searchFilters: {
                only_liked_by_me: true,
                limit: 10,
                search: '',
            }
        }
    },

    async mounted() {
        this.getFavourites();
    },

    created() {
        GlobalEventEmitter.$on('doFavouritesSearch', (e) => { this.searchPois(e) })
    },

    beforeDestroy() {
        GlobalEventEmitter.$off('doFavouritesSearch')
    },
    methods: {

        async getFavourites() {
            const loading = this.$vs.loading();
            await this.searchPois(this.searchFilters)
            loading.close();
        },
        newTrip() {
            GlobalEventEmitter.$emit('showDialog', NewTripDialog, this.newTripCallback) // chiedo il nome
        },
        newTripCallback(e) {
            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                this.$router.push({
                    name: 'Viaggio'
                })
            }
        },

        async searchPois(p) {
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/pois', p);
            GlobalEventEmitter.$emit('favouritesSearchComplete');

            // if status code is 200, show a success message 
            if (response.status == 200) {
                if(response.data.data != null){
                    this.pois = response.data.data;
                } else {
                    this.pois = []
                }
                
                console.log(this.pois)
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('favourites.messages.unableToLoadFavourites'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.favourites {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.favourites-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

/* MOBILE */
@media (max-width: 600px) {
    .favourites-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
    }

}
</style>
