<template>
<div class="trip-container" :class="{'print': $route.meta.print}" :style="{padding: $route.meta.print ? '20px' : undefined, boxSizing: $route.meta.print ? 'border-box' : undefined}">

    <div class="map-gradient" v-if="!$route.meta.print">
        <OpenStreetMap style="width: 100%; height: 150px; " :controls-enabled="false" @click.native="getAllPoisCoords" />
        <div class="gradient"> </div>
        <vs-tooltip v-model="tripAddCtxMenu" left shadow interactivity style="position: absolute; right: 0px; margin-top: -25px; justify-content: flex-end; z-index: 2000;" v-if="!$route.meta.print">
            <vs-button size="large" circle style="height: 50px;" @click="tripAddCtxMenu = !tripAddCtxMenu">
                <img src="../../assets/icons/add.png" style="width: 20px;" />

            </vs-button>
            <template #tooltip>
                <TripAddCtxMenu :callback="tripAddCtxMenuCallback" />
            </template>

        </vs-tooltip>
    </div>

    <div class="print-header" v-else>
        <img src="../../assets/images/logo.png" style="width: auto; height: 100px;" />
    </div>

    <div class="content">

        <div class="header" style="width: 100%;">
            <h3 style="display: flex; align-items: center; gap: 10px;">{{ trip != null ? trip.name : '' }} <img v-if="!$route.meta.print" @click="editTripName" style="cursor: pointer; width: 20px;" src="../../assets/icons/edit.png" /></h3>
            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <p v-if="trip != null && !hasNoDates(trip)">{{ (new Date(trip.start_date)).toLocaleDateString((user != null && user.language != null ? user.language.name.toLowerCase() : 'en')) }} - {{ (new Date(trip.end_date)).toLocaleDateString((user != null && user.language != null ? user.language.name.toLowerCase() : 'en')) }}</p>
                <p v-else>-</p>
                <vs-tooltip v-model="upgradeTooltip" not-arrow not-hover :bottom="isMobile" :left="!isMobile" border color="#EF7E18" style="padding: 0; margin: 0;" v-if="user != null">
                    <vs-button :disabled="trip == null || trip.pois == null || trip.pois.length == 0" @click="exportToPdf" :loading="exportLoading" transparent v-if="!$route.meta.print" class="export-pdf" href="#" style="display: flex; align-items: center; gap: 2px;"><img src="../../assets/icons/export.png" style="width: 20px;" /> {{ $t('trips.exportToPdf') }}</vs-button>

                    <template #tooltip>
                        <div class="content-tooltip">
                            <h4 class="center">
                                {{ $t('common.thisFeatureIsOnlyAvailableInPremiumVersion') }}
                            </h4>
                            <footer>
                                <vs-button block size="large" @click="upgrade" :loading="upgradeLoading">
                                    {{ $t('profile.subscription.upgrade') }}
                                </vs-button>
                            </footer>
                        </div>
                    </template>
                </vs-tooltip>
            </div>
        </div>

        <template v-if="trip != null && trip.pois != null && trip.pois.length > 0">
            <!-- STANDARD GROUPING -->
            <template v-for="(day, i) in dateRange">
                <DayDivider :key="'date-'+day.toISOString()" :day-no="i+1" :date="day"/>
                <div class="pois" :key="'stays-'+day" style="margin-bottom: 15px;" v-if="!$route.meta.print || days_stays[i].length > 0">
                        <TripPoi v-for="poi in days_stays[i]" :key="'poi-'+poi.id" :poi="poi" :load-details="false" @delete="deletePoi" @update="updatePoi" :type="poi.is_custom_poi ? 'Stay' : 'POI'" :action="$route.meta.print ? '' : 'more'" />
                </div>
                <draggable :key="'pois-'+day" class="pois" :list="days_pois[i]" group="pois" @start="drag = true" @end="drag = false" v-bind="dragOptions" v-if="!$route.meta.print || days_pois[i].length > 0">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <TripPoi v-for="poi in days_pois[i]" :key="'poi-'+poi.id"  :poi="poi" :load-details="false" @delete="deletePoi" @update="updatePoi" :type="poi.is_custom_poi ? 'Stay' : 'POI'" :action="$route.meta.print ? '' : 'more'" :detailed="$route.meta.print" />
                    </transition-group>
                </draggable>
            </template>
        </template>
        <div v-else style="display: flex; flex-direction: column; gap: 20px; align-items: center;">
            <img src="../../assets/images/no_trips.png" style="width: 50%;" />
            <h3>{{ $t('trips.noPoi') }}</h3>
        </div>

    </div>

</div>
</template>

<script>
import draggable from 'vuedraggable';
import DayDivider from '../DayDivider.vue';
import OpenStreetMap from '../OpenStreetMap.vue';
import TripPoi from '../TripPoi.vue';
import TripAddCtxMenu from '../ctx-menu/TripAddCtxMenu.vue';
import NewStayDialog from '../dialogs/NewStayDialog.vue';
import {
    GlobalEventEmitter
} from '../../utils/GlobalEventEmitter';
import {
    apiCall
} from '@/utils/ApiMiddleware.js';
import EditTripDialog from '../dialogs/EditTripDialog.vue';
export default {
    name: "trip",
    props: {
        user: {
            type: Object,
            required: false
        },

        isMobile: {
            type: Boolean,
            required: false
        }
    },
    components: {
        OpenStreetMap,
        DayDivider,
        TripPoi,
        TripAddCtxMenu,
        draggable
    },

    data() {
        return {
            tripAddCtxMenu: false,
            trip: null,
            exportLoading: false,
            dateRange: [],
            days_pois: [],
            days_stays: [],
            drag: false,

            upgradeTooltip: false,
            upgradeLoading: false,
        }
    },

    async mounted() {
        if (this.$route.meta.print) {
            // find element with class tourial-app and set overflow-y to scroll, find body and set height to unset
            var el = document.getElementsByClassName('tourial-app');
            if (el != null && el.length > 0) {
                el[0].style.overflowY = 'scroll';
            }
            document.body.style.height = 'unset';

            // remove blured class from body
            document.body.classList.remove('blured');
        }

        const loading = this.$vs.loading();
        await this.getTrip();

        window.pdfStatus = 'ready';
        loading.close();
    },

    methods: {
        upgrade() {
            this.upgradeLoading = true;
            setTimeout(() => {
                this.upgradeLoading = false;
                this.upgradeTooltip = false;
                this.$router.push({
                    name: 'Sottoscrizione'
                })
            }, 1000);
        },

        splitPoisToDays() {
            this.dateRange = this.calcDateRange();

            // divide poi list into sublists by day (into days array)
            this.days_pois = this.dateRange.map((day) => {
                return this.trip.pois.filter(e => day >= new Date(e.start_date) && day <= new Date(e.end_date) && !e.is_custom_poi);
            });

            // divide stay list into sublists by day (into days array)
            this.days_stays = this.dateRange.map((day) => {
                return this.trip.pois.filter(e => day >= new Date(e.start_date) && day <= new Date(e.end_date) && e.is_custom_poi);
            });

        },

        hasNoDates(trip) {
            var s = new Date(trip.start_date);
            var e = new Date(trip.end_date);
            if (s.getFullYear() <= 1970 || e.getFullYear() <= 1970) {
                return true;
            }
            return false;
        },
        editTripName() {
            GlobalEventEmitter.$emit('showDialog', EditTripDialog, (name) => {
                if (name != null && name.length > 0) {
                    this.trip.name = name;
                    this.saveTrip();
                }
            })
        },

        async deletePoi(poi) {
            var p = this.trip.pois.find(e => e.id == poi.id); // salvo 
            this.trip.pois = this.trip.pois.filter(e => e.id != poi.id); // rimuovo
            const loading = this.$vs.loading();
            if (!(await this.saveTrip())) { // aggiorno il viaggio
                // se non va a buon fine ri-aggiungo il poi
                this.trip.pois.push(p);
            } else {
                this.getTrip();
            }
            loading.close();
        },

        async updatePoi(poi) {
            var p = this.trip.pois.find(e => e.id == poi.id); // salvo 
            this.trip.pois = this.trip.pois.filter(e => e.id != poi.id); // rimuovo
            // aggiungo quello aggiornato
            this.trip.pois.push(poi);
            const loading = this.$vs.loading();
            if (!(await this.saveTrip())) { // aggiorno il viaggio
                console.log('FAIL', p)
                // TODO TORNARE INDIETRO
            } else {
                this.getTrip();
            }
            loading.close();
        },

        calcDateRange() {
            var daysOfTrip = [];
            if (this.trip == null || this.hasNoDates(this.trip)) {
                return daysOfTrip;
            }
            var a = new Date(this.trip.start_date);
            var z = new Date(this.trip.end_date);
            for (var d = a; d <= z; d.setDate(d.getDate() + 1)) {
                var x = new Date(d);
                daysOfTrip.push(x);
            }
            return daysOfTrip;
        },

        async getTrip() {

            // use apiCall to make a request to /trips
            const response = await apiCall('GET', '/journey/' + this.$route.params.tripId);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (response.data != null) {
                    this.trip = response.data;
                }
                console.log(this.trip)
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.unableToLoadTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async newStayDialogCallback(e) {
            if (e.name.length > 0 && e.street.length > 0 && e.start_date.length > 0 && e.end_date.length > 0) {
                e.start_date += 'T00:00:00Z';
                e.end_date += 'T00:00:00Z';
                if (this.trip.pois == undefined) {
                    this.trip.pois = []
                }
                this.trip.pois.push(e)
                const loading = this.$vs.loading();
                if (!(await this.saveTrip())) {
                    this.trip.pois = this.trip.pois.filter(e => e.id == null)
                } else {
                    this.getTrip();
                }
                loading.close();
            }
        },

        tripAddCtxMenuCallback(e) {
            if (e == 'place') {
                this.$router.push({
                    name: 'Esplora',
                    params: {
                        addToTripMode: true,
                        trip: this.trip
                    }
                })
            } else if (e == 'structure') {
                // aggiungi struttura
                GlobalEventEmitter.$emit('showDialog', NewStayDialog, this.newStayDialogCallback) // chiedo il nome
            }
            this.tripAddCtxMenu = false;
            var el = document.getElementsByClassName('vs-tooltip');
            if (el != null && el.length > 0) {
                el[0].remove();
            }
        },

        async saveTrip(shortNotification = false) {

            const response = await apiCall('PUT', '/journeys/' + this.trip.id, this.trip);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show sucecss message
                this.$vs.notification({
                    title: !shortNotification ? this.$t('common.messages.success') : undefined,
                    text: this.$t('trips.messages.tripUpdated'),
                    color: 'success',
                    position: 'top-right',
                    duration: !shortNotification ? 4000 : 1000,
                });
                return true;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cantUpdateTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            return false;
        },

        async exportToPdf() {
            if (this.user.subscription == null && !this.user.is_partner && !this.user.is_admin) {
                this.upgradeTooltip = true;
                return;
            }

            this.exportLoading = true;
            const response = await apiCall('GET', '/journeys/' + this.trip.id + '/export', null, false, 'text/plain', true);
            this.exportLoading = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response)
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = 'tourial_trip_' + this.trip.id + '.pdf';

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cannotExport'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async getAllPoisCoords() {
            if (this.trip.pois == null || this.trip.pois.length == 0) {
                return;
            }
            const loading = this.$vs.loading();
            var tripPois = [];
            for (var i = 0; i < this.trip.pois.length; i++) {
                var poi = this.trip.pois[i];
                if (poi.is_custom_poi) {
                    continue;
                }

                const response = await apiCall('GET', '/pois/' + poi.id);
                if (response.status == 200) {
                    tripPois.push(response.data);
                } else if (response.status != 0) {
                    break;
                }
            }
            this.$router.push({
                name: 'Esplora',
                params: {
                    tripPois
                }
            })
            loading.close();
        },
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                delay: 250,
                delayOnTouchOnly: true,
            };
        }

    },

    watch: {
        'trip.pois'() {
            this.splitPoisToDays();
        },

        drag(n, o) {
            if (!n && o) {
                // drag finished, merge days_pois and days_stays into pois (keeping the order)

                // recalculate date of each poi
                for (var i = 0; i < this.dateRange.length; i++) {
                    for (var j = 0; j < this.days_pois[i].length; j++) {
                        this.days_pois[i][j].start_date = this.dateRange[i].toISOString();
                        this.days_pois[i][j].end_date = this.dateRange[i].toISOString();
                    }
                }

                var pois = [];

                for (i = 0; i < this.dateRange.length; i++) {
                    pois = pois.concat(this.days_pois[i]);
                }

                // add 'position' property to each poi
                for (i = 0; i < pois.length; i++) {
                    pois[i].position = i;
                }

                // add stays, the stay with same id must be added only once
                for (i = 0; i < this.trip.pois.length; i++) {
                    if (this.trip.pois[i].is_custom_poi) {
                        if (pois.find(e => e.id == this.trip.pois[i].id) == undefined) {
                            pois.push(this.trip.pois[i]);
                        }
                    }
                }

                this.trip.pois = pois;
                this.saveTrip(true);

            }
        }

    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #f0cdae;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}
</style><style scoped>
.content-tooltip {
    padding: 10px;
    box-sizing: border-box;
}

.content-tooltip button {
    margin: 0;
    margin-bottom: 5px;
}

.trip-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 15px;
    overflow-y: auto;
    overflow-x: hidden;
}

.content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.pois,
.pois span {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.map-gradient {
    height: 150px;
    width: 100%;
    position: relative;
}

.map-gradient div:first-child {
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
}

.map-gradient .gradient {
    height: 150px;
    z-index: 1900;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.0001) 15%, rgba(255, 255, 255, 1) 100%);
}

.header {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
}

.print .header {
    padding-top: 50px;
}

p,
h3 {
    margin: 0;
}

.vs-button {
    margin: 0 !important;
}

/* MOBILE */
@media (max-width: 600px) {
    .content {
        padding-left: 0px;
        padding-right: 0px;
        box-sizing: border-box;

    }

    .trip-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        width: 100% !important;
    }

}

@media print {
    @page {
        size: 210mm 297mm;
        /* A4 size */

        /* magin on top and 0 on others */
        margin: 30px 0 30px 0;

    }

    .print-header {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        position: absolute;
        top: 0;
    }

    .print-header img {
        height: 30px !important;
    }

    /* define page break rule */
    .daydivider-container
    /*, .day-and-first-poi*/ {
        page-break-inside: avoid;
    }
}
</style>
