<template>
<div ref="content" class="trippoi-container" :class="{'with-details': detailed, 'print': $route.meta.print}" @click="openPoi">
    <div class="sx">
        <img style="width: auto; height: 64px;" v-if="type == 'POI'" :src="baseUri + '/targets/' + poi.target_id + '/picture'" />
        <img style="width: auto; height: 64px;" v-else-if="type == 'Stay'" src="../../public/osm/targets/HOTEL.png" />
        <div class="content">
            <p class="bold">{{poiDetails != null ? poiDetails.name : ''}}</p>
            <p class="light">{{poiDetails != null ? poiDetails.street : ''}}</p>
        </div>
    </div>
    <vs-tooltip v-if="action == 'more'" v-model="tripPoiCtxMenu" left shadow interactivity>
        <vs-button dark style="height: 40px;" @click="(e) => { e.stopPropagation(); tripPoiCtxMenu = !tripPoiCtxMenu;}">
            <img src="../assets/icons/more.png" style="width: 24px;" />

        </vs-button>
        <template #tooltip>
            <TripPoiCtxMenu :callback="tripPoiCtxMenuCallback" />
        </template>

    </vs-tooltip>

    <template v-else-if="action == 'like'">
        <vs-button @click="(e) => {e.stopPropagation(); removePoiLike();}" dark color="#FFE6D0" style="width: 50px;"><img src="../assets/icons/favourite.png" style="width:24px;" /></vs-button>
    </template>
    <div class="details" v-if="detailed">
        <div class="poi-section">
            <p class="bold">{{ $t('poi.yearOfConstruction') }}</p>
            <p>{{ poiDetails != null ? poiDetails.year_built : '----' }}</p>
        </div>

        <div class="poi-section">
            <p class="bold">{{ $t('poi.author') }}</p>
            <p>{{ poiDetails != null ? (poiDetails.author == '' ? '-' : poiDetails.author) : 'Loading..' }}</p>
        </div>

        <div class="poi-section">
            <p class="bold">{{ $t('poi.description') }}</p>
            <p>{{ poiDetails != null ? poiDetails.description : 'Loading..'}}</p>
        </div>
    </div>
</div>
</template>

<script>
import TripPoiCtxMenu from './ctx-menu/TripPoiCtxMenu.vue';
import {
    apiCall,
    baseUri
} from '../utils/ApiMiddleware';
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
import TripDateDialog from './dialogs/TripDateDialog.vue'
import EditStayDialog from './dialogs/EditStayDialog.vue';
export default {
    name: "tripPoi",
    components: {
        TripPoiCtxMenu
    },
    props: {
        poi: Object,
        type: {
            type: String,
            default: 'POI'
        },

        loadDetails: { // a volte (eg. nei viaggi), ho già tutti i dettagli caricati, qui la chiamata per ottener ele altre informazioni è superflua, mentre nei preferiti serve
            type: Boolean,
            default: true
        },

        action: { // bottone action a destra, default i 3 puntini
            type: String, // more | like -> rimuove dai preferiti
            default: 'more' // 3 puntini con menu contestuale
        },

        detailed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tripPoiCtxMenu: false,
            poiDetails: null,
            baseUri
        }
    },

    mounted() {
        if (this.poi != null) {
            if (this.loadDetails || this.detailed) {
                this.getPoi(this.poi.id);
            } else {
                this.poiDetails = JSON.parse(JSON.stringify(this.poi));
            }
        }
    },

    methods: {
        openPoi() {
            if (this.type == 'POI') {
                this.$router.push('/explore/poi/' + this.poi.id)
            }
        },

        async removePoiLike() {
            if (this.poi == null) {
                return;
            }
            this.poi.liked = !this.poi.liked;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/pois/' + this.poi.id + '/like', {
                liked: false
            })
            if (response.status == 200) {
                // success
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('common.poi') + ' ' + this.$t('common.removedFrom').toLowerCase() + ' ' + this.$t('common.favourites').toLowerCase(),
                    color: 'success',
                    position: 'top-right'
                })
                this.$emit('change');
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotRemoveFromFavourites'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async getPoi(id) {
            const loading = this.$vs.loading({
                target: this.$refs.content
            });
            const response = await apiCall('GET', '/pois/' + id);
            if (response.status == 200) {
                this.poiDetails = response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: 'Qualcosa è andato storto!',
                    text: 'Impossibile caricare il punto di interesse, riprova più tardi.',
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        },

        tripPoiCtxMenuCallback(e) {
            this.tripPoiCtxMenu = false;
            if (e == 'edit') {
                if (this.type == 'POI') {
                    GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.editPoiDialogCallback, this.poiDetails)
                } else if (this.type == 'Stay') {
                    GlobalEventEmitter.$emit('showDialog', EditStayDialog, this.editStayDialogCallback, this.poiDetails)
                }

            } else if (e == 'delete') {
                this.$emit('delete', this.poiDetails)
            }

            var el = document.getElementsByClassName('vs-tooltip');
            if (el != null && el.length > 0) {
                el[0].remove();
            }

        },

        editPoiDialogCallback(e) {
            if (e != null && e.length > 0) {
                this.poiDetails.start_date = e + 'T00:00:00Z';
                this.poiDetails.end_date = e + 'T00:00:00Z';
                this.$emit('update', this.poiDetails)
            }
        },

        editStayDialogCallback(e) {
            if (e != null) {
                this.poiDetails.start_date = e.start_date + 'T00:00:00Z';
                this.poiDetails.end_date = e.end_date + 'T00:00:00Z';
                this.poiDetails.name = e.name;
                this.poiDetails.street = e.street;
                this.$emit('update', this.poiDetails)
            }
        }
    }
}
</script>

<style scoped>
.trippoi-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    padding: 20px 15px;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    
}

.trippoi-container.print {
    background: rgb(248, 248, 248)!important;
}

.sx {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vs-button.like {
    background: #FFE6D0 !important;
}

p {
    margin: 0;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.trippoi-container.with-details {
    flex-direction: column;
    gap: 10px;
}

.trippoi-container.with-details .sx {
    width: 100%;
}

.trippoi-container .poi-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}


/* remove shadow in print mode */
@media print {
    .trippoi-container {
        box-shadow: none!important;
    }
}
</style>
