<template>
<div>
    <div class="editstaydialog-container">
        <h2>{{ $t('trips.editStay') }}</h2>

        <vs-input v-model="stay.name" :placeholder="$t('common.name')" block>
            <template #icon>
                <img src="../../assets/icons/input/edit.png" style="width: 32px;" />
            </template>
        </vs-input>
        <vs-input v-model="stay.street" :placeholder="$t('common.street')" block>
            <template #icon>
                <img src="../../assets/icons/input/place.png" style="width: 20px;" />
            </template>
        </vs-input>
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-input v-model="stay.start_date" :placeholder="$t('common.from')" style="width: 48%" type="date">
            </vs-input>
            <vs-input v-model="stay.end_date" :state="stay.end_date < stay.start_date ? 'danger' : undefined" :min="stay.start_date" :placeholder="$t('common.to')" style="width: 48%" type="date">
            </vs-input>
        </div>
        <div style="display: flex; width: 100%; justify-content: space-between; gap: 10px;">
            <vs-button size="large" border block @click="callback(null, true)">
                {{ $t('common.cancel') }}
            </vs-button>
            <vs-button size="large"  :disabled="stay.name.length < 1 || stay.street.length < 1 || stay.end_date.length < 1 || stay.start_date.length < 1 || stay.end_date < stay.start_date" block @click="callback(stay, true)">
                {{ $t('common.continue') }}
            </vs-button>
        </div>
    </div>

</div>
</template>

    
<script>
export default {
    name: "editStayDialog",
    props: {
        callback: Function,
        data: Object
    },
    data() {
        return {
            stay: {
                name: '',
                street: '',
                start_date: '',
                end_date: '',
                is_custom_poi: true
            }
        }
    },

    mounted() {
        console.log(this.data);
        if (this.data != null) {
            this.stay.name = this.data.name;
            this.stay.street = this.data.street;
            this.stay.start_date = this.data.start_date.split('T')[0];
            this.stay.end_date = this.data.end_date.split('T')[0];
            this.stay.id = this.data.id;
        }
    },
    components: {}
}
</script>

    
<style scoped>
p,
h3,
h2 {
    margin: 0;
}

h2 {
    margin-bottom: 20px;
}

.editstaydialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    gap: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}
</style>
