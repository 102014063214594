<template>
<div class="favouritesearch-container">
    <div class="searchbar" :class="{'with-expansion': filtersVisible}">
        <div class="sx">
            <img src="../assets/icons/search/location.png" style="height: 30px; width: auto;" @click="focusInput" />
            <vs-input id="favouriteSearch" :placeholder="$t('favourites.search')" :loading="loading" v-model="searchFilters.search" />
        </div>
        <div class="dx">
            <vs-button circle transparent @click="filtersVisible = !filtersVisible">
                <img v-if="filtersVisible" src="../assets/icons/search/filters-active.png" style="width: 24px;" />
                <img v-else src="../assets/icons/search/filters.png" style="width: 24px;" />
            </vs-button>
            <vs-button circle class="search-btn" @click="doSearch">
                <img src="../assets/icons/search/search.png" style="width: 18px;"/>

            </vs-button>
        </div>
    </div>

    <FavouriteSearchFilters v-show="filtersVisible" v-if="!isMobile" :change="applyFilters" :close="() => {filtersVisible = false}"/>

    <slideout-panel></slideout-panel>

</div>
</template>

<script>
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
import FavouriteSearchFilters from './FavouriteSearchFilters.vue';

export default {
    name: "favouriteSearch",
    props: {
        isMobile: Boolean
    },
    data() {
        return {
            loading: false,
            filtersVisible: false,
            filtersMobilePanel: null,
            searchFilters: {
                only_liked_by_me: true,
                limit: 10,
                search: '',
            }
        }
    },

    watch: {
        filtersVisible(n) {
            var vm = this;
            if (!this.isMobile) {
                return;
            }

            if (n) {
                this.filtersMobilePanel = this.$showPanel({
                    component: FavouriteSearchFilters,
                    openOn: 'bottom',
                    props: {
                        change: this.applyFilters,
                        close: () => {
                            this.filtersVisible = false
                        }
                    }
                });
                this.filtersMobilePanel.promise
                    .then(result => {
                        console.log(result)
                        vm.filtersVisible = false
                    });
            } else {
                if (this.filtersMobilePanel != null) {
                    this.filtersMobilePanel.hide();
                    this.filtersMobilePanel = null;
                }
            }

        }
    },
    methods: {

        applyFilters(n){
            this.searchFilters.order = n.sortOrder + '(' + n.sortBy + ')';
            this.doSearch();
        },

        focusInput() {
            document.getElementById("vs-input--favouriteSearch").focus();
        },

        doSearch() {
            var vm = this;
            vm.loading = true;
            GlobalEventEmitter.$emit('doFavouritesSearch', vm.searchFilters);
        }
    },

    created() {
        GlobalEventEmitter.$on('favouritesSearchComplete', () => {
            this.loading = false
        })
    },

    beforeDestroy() {
        GlobalEventEmitter.$off('favouritesSearchComplete')
    },

    components: {
        FavouriteSearchFilters
    },

}
</script>

<style scoped>
.favouritesearch-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    z-index: 100;
}

.search-btn {
    width: 40px;
    height: 40px;
}

.sx,
.dx {
    display: flex;
    align-items: center;
}

.searchbar {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
    z-index: 2;
}

.searchbar.with-expansion {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .favouritesearch-container {
        top: 20px;
        left: 0;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }

    input {
        flex-grow: 1;
    }

    .search-btn {
        width: 45px;
        height: 45px;
        min-width: 45px;
    }
}
</style><style>
.favouritesearch-container .vs-input {
    background: white;
}

/* MOBILE */
@media (max-width: 600px) {

    .favouritesearch-container input {
        width: 100% !important;
        height: 50px !important;
        font-size: 18px;
        padding-left: 0;
    }

    ::placeholder {
        font-size: 18px !important;
        color: rgba(var(--grey-75));
    }

}
</style>
