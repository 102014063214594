<template>
<div class="favouritesearchfilter-container">
    <h3>{{ $t('common.sorting') }}</h3>
    <div class="actions">
        <vs-button transparent @click="close()">{{ $t('common.cancel') }}</vs-button>
        <vs-button transparent @click="reset">{{ $t('common.reset') }}</vs-button>
    </div>
    <div class="fields">
        <vs-select :label="$t('common.sortBy').toUpperCase()" v-model="sort.sortBy" block style="margin-top: 20px;">
            <vs-option :label="$t('common.addedDate')" value="liked_at">
                {{ $t('common.addedDate') }}
            </vs-option>
            <vs-option :label="$t('common.alphabetical')" value="name">
                {{ $t('common.alphabetical') }}
            </vs-option>
        </vs-select>

        <div class="divider"></div>
        <vs-select :label="$t('common.sorting').toUpperCase()" v-model="sort.sortOrder" block>
            <vs-option :label="$t('common.descending')" value="desc">
                {{ $t('common.descending') }}
            </vs-option>
            <vs-option :label="$t('common.ascending')" value="asc">
                {{ $t('common.ascending') }}
            </vs-option>

        </vs-select>

        <vs-button size="large" style="margin-top: 20px;" @click="applyFilters">{{ $t('explore.applyFilters').toUpperCase() }}</vs-button>
    </div>
</div>
</template>

<script>
export default {
    name: 'favouritesSearchFilters',
    props: {
        change: Function,
        close: Function
    },
    data() {
        return {
            sort: {
                sortBy: 'liked_at',
                sortOrder: 'asc'
            }
        }
    },

    methods: {
        reset() {
            this.sort = {
                sortBy: 'liked_at',
                sortOrder: 'asc'
            }
        },

        applyFilters() {
            this.change(this.sort)
            this.close()
        }
    }
}
</script>

<style scoped>
.favouritesearchfilter-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: white;
    gap: 5px;
    padding-bottom: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
    position: absolute;
    margin-top: 50px;
}

.divider {
    margin-top: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.fields {
    padding-left: 13px;
    padding-right: 13px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

h3 {
    margin: 0;
    margin-top: 15px;
}

/* DESKTOP ONLY */
@media (min-width: 600px) {
    .favouritesearchfilter-container {
        width: calc(100% - 50px);
    }
}

@media (max-width: 480px) {
    .favouritesearchfilter-container {
        width: calc(100% - 40px);
    }
}
</style>
