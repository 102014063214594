import { render, staticRenderFns } from "./NewReview.vue?vue&type=template&id=f9c27ba4&scoped=true&"
import script from "./NewReview.vue?vue&type=script&lang=js&"
export * from "./NewReview.vue?vue&type=script&lang=js&"
import style0 from "./NewReview.vue?vue&type=style&index=0&id=f9c27ba4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9c27ba4",
  null
  
)

export default component.exports